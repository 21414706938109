import React from 'react';
import { Link } from 'react-router-dom'

import logo from '../images/logo.svg'

function Header() {

    return (
        <header className="container-fluid text-center">
            <Link to="/" className="logo">
                <img src={logo} alt="" className="mw-100" />
            </Link>
        </header>
    )
}

export default Header;