import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import update from 'react-addons-update';
import Modal from 'react-bootstrap/Modal'

import SimpleReactValidator from 'simple-react-validator';

function EditUser() {

    const validator = new SimpleReactValidator()

    const [errorMessages, setErrorMessages] = useState({})

    function submitForm(userId) {
        if (validator.allValid()) {
            savePhonebookContact(userId)
        } else {
            validator.showMessages();
            setErrorMessages(validator.errorMessages)
        }
    }

    const history = useHistory()
    const userId = useParams()
    const [existingUsers, setExistingUsers] = useState(JSON.parse(localStorage.getItem('phonebookContacts')))
    var referentUserVar = {}

    existingUsers.map((v, i) => {
        if(v.id == userId.userId) {
            referentUserVar = v
        }
    })

    const [referentUser, setReferentUser] = useState(referentUserVar)
    const [numbers, setNumbers] = useState(referentUser.numbers)

    useEffect(() => {
        setReferentUser(prevValues => ({...prevValues, numbers: numbers }))
    }, [numbers])

    function addNumberRow() {
        const tempObject = {phoneNumber: '', phoneLabel: ''}

        setNumbers(prevValues => 
            [...prevValues, tempObject]
        )
    }

    function handleNumberRow(value, index, property) {
        let initialArray = numbers
        let newArray = update(initialArray, {[index]: {[property]: {$set: value}}})

        setNumbers(newArray)
    }

    function removeNumberRow(index) {
        let initialArray = numbers
        let newArray = update(initialArray, {$splice: [[index, 1]]})

        setNumbers(newArray)
    }

    function savePhonebookContact(id) {

        var tempReferentUserId = 0

        existingUsers.map((v, i) => {
            if(v.id == id) {
                tempReferentUserId = i
            }
        })

        var tempUsers = existingUsers
        tempUsers[tempReferentUserId] = referentUser;

        setExistingUsers(tempUsers)
        
        localStorage.setItem('phonebookContacts', JSON.stringify(existingUsers))

        handleModal('update')

    }

    
    const [modalIsOpen, setModalIsOpen] = useState(false)

    function deletePhonebookContact(id) {

        var tempReferentUser = null
        
        existingUsers.map((v, i) => {
            if(v.id == id) {
                tempReferentUser = i
            }
        })

        var tempExistingUsers = existingUsers
        tempExistingUsers.splice(tempReferentUser, 1)


        setModalIsOpen(false)

        localStorage.setItem('phonebookContacts', JSON.stringify(tempExistingUsers))

        history.push('/')

    }

    const [modalAction, setModalAction] = useState('')

    function handleModal(action) {
        if(action === 'update') {
            setModalAction('update')
            setModalIsOpen(true)
        }
        else if(action === 'delete') {
            setModalAction('delete')
            setModalIsOpen(true)
        }
        else {
            setModalIsOpen(false)
        }
    }

    return (
        <>
            <div className="container add-user">
                <div className="row flex-nowrap">
                    <div className="col-auto d-none d-md-flex">
                        <div className="avatar">
                            <i className="cir ci-upload"></i>
                        </div>
                    </div>
                    <div className="col">
                        <div className="main-line d-none d-md-flex">
                            <button onClick={() => history.goBack()} className="btn"><i className="cir ci-back"></i></button>

                            <div className="user-actions ml-auto">
                                <button className="btn d-flex align-items-center" onClick={() => handleModal('delete')}><span className="body-3 mr-3">Delete</span> <i className="cir ci-trash"></i></button>
                            </div>
                        </div>

                        <div className="main-line mobile d-md-none">
                            <button onClick={() => history.goBack()} className="btn mr-auto ml-n2"><i className="cir ci-back"></i></button>

                            <div className="user-actions">
                                <button className="btn" onClick={() => handleModal('delete')}><i className="cir ci-trash"></i></button>
                            </div>
                        </div>

                        <div className="mobile-user-display d-md-none">
                            <div className="avatar">
                                <i className="cir ci-upload"></i>
                            </div>
                        </div>

                        <div className="input-fileds">
                            <div className="form-group">
                                <label className="body-2"><i className="cir ci-person"></i><b>full name</b></label>
                                <input type="text" value={referentUser.fullName} onChange={(e) => setReferentUser(prevValues => ({...prevValues, fullName: e.target.value }))} className="form-control width-limited" placeholder="Full name" />
                                {validator.message('fullName', referentUser.fullName, 'required|min:2|max:30')}
                                <small className="text-danger">{errorMessages['fullName']}</small>
                            </div>
                            <div className="form-group">
                                <label className="body-2"><i className="cir ci-email"></i><b>email</b></label>
                                <input type="email" value={referentUser.email} onChange={e => setReferentUser(prevValues => ({...prevValues, email: e.target.value }))}  className="form-control width-limited" placeholder="Email" />
                                {validator.message('email', referentUser.email, 'required|email')}
                                <small className="text-danger">{errorMessages['email']}</small>
                            </div>
                            <div className="form-group">
                                <label className="body-2"><i className="cir ci-phone"></i><b>numbers</b></label>
                                {
                                    numbers.map((v, i) => 

                                    <>
                                        <div className="input-collection" key={i}>
                                            <input type="number" value={v.phoneNumber} className="form-control" placeholder="Number" onChange={(e) => handleNumberRow(e.target.value, i, 'phoneNumber')} required />
                                            <input type="text" value={v.phoneLabel} className="form-control" placeholder="Label" onChange={(e) => handleNumberRow(e.target.value, i, 'phoneLabel')} required />
                                            <button className="btn btn-sm btn-outline-gray-300 remove-row" onClick={() => removeNumberRow(i)}><i className="cir ci-times"></i></button>
                                        </div>
                                        
                                        {validator.message(`number${i}`, v.phoneNumber, 'required|phone')}
                                        <small className="text-danger">{errorMessages[`number${i}`]}</small>
                                        
                                        {validator.message(`label${i}`, v.phoneLabel, 'required|alpha')}
                                        <small className="text-danger">{errorMessages[`label${i}`]}</small>
                                    </>
                                    )
                                }

                                <div>
                                    <div className="add-new-row" onClick={() => addNumberRow()}>
                                        <button className="btn btn-sm btn-outline-primary"><i className="cir ci-times"></i></button>
                                        <span>Add number</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between new-user-actions">
                            <button onClick={() => history.goBack()} className="btn btn-gray-400">Cancel</button>
                            <button className="btn btn-primary" onClick={() => submitForm(userId.userId)}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        
            

            <Modal
                show={modalIsOpen}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleModal}
                className="delete-modal"
                >
                    {
                        modalAction === 'update' ? (
                            <>
                                <div className="modal-header">
                                    Update
                                </div>

                                <div className="modal-body">
                                    <p>Contact information has been been updated</p>

                                    <div className="d-flex">
                                        <button className="btn btn-primary mx-auto" onClick={() => setModalIsOpen(false)}>Ok</button>
                                    </div>

                                </div>
                            </>
                        )
                        : null
                    }
                    {
                        modalAction === 'delete' ? (
                            <>
                                <div className="modal-header">
                                    Delete
                                </div>
            
                                <div className="modal-body">
                                    <p>Are you sure you want to delete this contact?</p>
            
                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-center">
                                        <button className="btn btn-gray-400 order-2 order-sm-1" onClick={() => setModalIsOpen(false)}>Cancel</button>
                                        <button className="btn btn-primary mb-3 mb-sm-0 order-1 order-sm-2" onClick={() => deletePhonebookContact(referentUser.id)}>Delete</button>
                                    </div>
            
                                </div>
                            </>
                        )
                        : null
                    }
            </Modal>
        </>
    )
}

export default EditUser;