import React, { useEffect } from 'react';
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import avatar from '../images/avatar.jpeg'
import update from 'react-addons-update';

function Home() {
    
    const [existingUsers, setExistingUsers] = useState(JSON.parse(localStorage.getItem('phonebookContacts')))
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [contactForDeletion, setContactForDeletion] = useState(0)

    const [filterParameter, setFilterParameter] = useState('all')
    const [searchParameter, setSearchParameter] = useState('')
    
    const [tempContact, setTempContact] = useState()

    function handleFavorite(v) {
        setTempContact(prevValues => (
            {...prevValues,
                isFavorite:     !v.isFavorite,
                fullName:       v.fullName,
                email:          v.email,
                imagePath:      v.imagePath,
                id:             v.id,
                numbers:        v.numbers
            }
        ))
    }

    useEffect(() => {
        if(tempContact !== undefined) {
            setExistingUsers(update(existingUsers, {$splice: [[existingUsers.findIndex(x => x.id == tempContact.id), 1, tempContact]]}))
        }
    }, [tempContact])

    
    useEffect(() => {
        localStorage.setItem('phonebookContacts', JSON.stringify(existingUsers))
    }, [existingUsers])


    function handleDeleteModal(id) {
        if(!modalIsOpen) {
            setModalIsOpen(true)
            setContactForDeletion(id)
        }
        else {
            setModalIsOpen(false)
            setContactForDeletion(0)
        }
    }

    function deletePhonebookContact(id) {

        var tempReferentUser = null
        
        existingUsers.map((v, i) => {
            if(v.id == id) {
                tempReferentUser = i
            }
        })

        var tempExistingUsers = existingUsers
        tempExistingUsers.splice(tempReferentUser, 1)

        localStorage.setItem('phonebookContacts', JSON.stringify(tempExistingUsers))

        setModalIsOpen(false)
    }

    return (
        <>
            <div className="container filter-search-container">
                <div className="contacts-filter body-1">
                    <span className={`filter-param ${filterParameter == 'all' ? 'active' : ''}`} onClick={() => setFilterParameter('all')}>All contacts</span>
                    <span className="separator"></span>
                    <span className={`filter-param ${filterParameter == 'favorites' ? 'active' : ''}`} onClick={() => setFilterParameter('favorites')}>My favorites</span>
                </div>

                <div className="contacts-search">
                    <div className="form-group">
                        <i className="cir ci-search"></i>
                        <input type="text" className="form-control" value={searchParameter} onChange={(e) => setSearchParameter(e.target.value)} />
                    </div>
                </div>
            </div>

            <div className="container contacts-wrap">
                <div className="row">
                    {
                        filterParameter == 'all' ?
                            <div className="col-sm-6 col-lg-3 contact-card-wrap">
                                <Link to="add-user" className="contact-card add-new">
                                    <i className="cir ci-plus"></i>
                                    <div className="body-2">Add new</div>
                                </Link>
                            </div>
                        :
                        null
                    }
                    {
                        existingUsers !== null && existingUsers.length ? (
                            existingUsers.map((v, i) => 
                                filterParameter == 'all' ?
                                    searchParameter.length ?
                                        v.fullName.toLowerCase().includes(searchParameter.toLowerCase()) ?
                                            <div className="col-sm-6 col-lg-3 contact-card-wrap" key={i}>
                                                <div className="contact-card">
                                                    <div className="user-actions">
                                                        <button className="btn"><i className={`cir ${v.isFavorite ? 'ci-heart-solid' : 'ci-heart'}`}></i></button>
                                                        <Link to={`/edit-user/${v.id}`} className="btn"><i className="cir ci-edit"></i></Link>
                                                        <button className="btn" onClick={() => handleDeleteModal(v.id)}><i className="cir ci-trash"></i></button>
                                                    </div>
                                                    <div className="user-info">
                                                        <img src={avatar} alt="" />
                                                        <Link to={`/users/${v.id}`} className="p-2 m-n2"><span>{v.fullName}</span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                        :
                                        <div className="col-sm-6 col-lg-3 contact-card-wrap" key={i}>
                                            <div className="contact-card">
                                                <div className="user-actions">
                                                    <button className="btn"
                                                        onClick={() => handleFavorite(v)}
                                                    ><i className={`cir ${v.isFavorite ? 'ci-heart-solid' : 'ci-heart'}`}></i></button>
                                                    <Link to={`/edit-user/${v.id}`} className="btn"><i className="cir ci-edit"></i></Link>
                                                    <button className="btn" onClick={() => handleDeleteModal(v.id)}><i className="cir ci-trash"></i></button>
                                                </div>
                                                <div className="user-info">
                                                    <img src={avatar} alt="" />
                                                    <Link to={`/users/${v.id}`} className="p-2 m-n2"><span>{v.fullName}</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                    filterParameter == 'favorites' ? 
                                        v.isFavorite == true ? 
                                        searchParameter.length ?
                                            v.fullName.toLowerCase().includes(searchParameter.toLowerCase()) ?
                                                <div className="col-sm-6 col-lg-3 contact-card-wrap" key={i}>
                                                    <div className="contact-card">
                                                        <div className="user-actions">
                                                            <button className="btn"><i className={`cir ${v.isFavorite ? 'ci-heart-solid' : 'ci-heart'}`}></i></button>
                                                            <Link to={`/edit-user/${v.id}`} className="btn"><i className="cir ci-edit"></i></Link>
                                                            <button className="btn" onClick={() => handleDeleteModal(v.id)}><i className="cir ci-trash"></i></button>
                                                        </div>
                                                        <div className="user-info">
                                                            <img src={avatar} alt="" />
                                                            <Link to={`/users/${v.id}`} className="p-2 m-n2"><span>{v.fullName}</span></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            :
                                            <div className="col-sm-6 col-lg-3 contact-card-wrap" key={i}>
                                                <div className="contact-card">
                                                    <div className="user-actions">
                                                        <button className="btn"><i className={`cir ${v.isFavorite ? 'ci-heart-solid' : 'ci-heart'}`}></i></button>
                                                        <Link to={`/edit-user/${v.id}`} className="btn"><i className="cir ci-edit"></i></Link>
                                                        <button className="btn" onClick={() => handleDeleteModal(v.id)}><i className="cir ci-trash"></i></button>
                                                    </div>
                                                    <div className="user-info">
                                                        <img src={avatar} alt="" />
                                                        <Link to={`/users/${v.id}`} className="p-2 m-n2"><span>{v.fullName}</span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        null
                                :
                                null
                            )
                        )
                        : null
                    }
                </div>
            </div>

            <Modal
                show={modalIsOpen}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleDeleteModal}
                className="delete-modal"
                >
                    <div className="modal-header">
                        Delete
                    </div>

                    <div className="modal-body">
                        <p>Are you sure you want to delete this contact?</p>

                        <div className="d-flex justify-content-between flex-column flex-sm-row align-items-center">
                            <button className="btn btn-gray-400 order-2 order-sm-1" onClick={() => setModalIsOpen(false)}>Cancel</button>
                            <button className="btn btn-primary mb-3 mb-sm-0 order-1 order-sm-2" onClick={() => deletePhonebookContact(contactForDeletion)}>Delete</button>
                        </div>

                    </div>
            </Modal>
    
        </>
    

    )
}

export default Home;