import React from 'react';
import { Link, useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import update from 'react-addons-update';

import SimpleReactValidator from 'simple-react-validator';


function AddUser() {

    const validator = new SimpleReactValidator()

    const [errorMessages, setErrorMessages] = useState({})

    function submitForm() {
        if (validator.allValid()) {
            savePhonebookContact()
        } else {
            validator.showMessages();
            setErrorMessages(validator.errorMessages)
        }
    }

    var localStorageInitial = null

    if(localStorage.getItem('phonebookContacts') === 'null') {
        localStorageInitial = JSON.stringify([])
    }
    else {
        localStorageInitial = localStorage.phonebookContacts
    }

    const [phonebookContacts, setPhonebookContacts] = useState(JSON.parse(localStorageInitial))
    const history = useHistory()


    const [numbers, setNumbers] = useState([])

    const [formValues, setFormValues] = useState({
        fullName:       '',
        email:          '',
        imagePath:      '',
        id:             null,
        isFavorite:     false,
        numbers:        numbers
    })

    useEffect(() => {
        setFormValues(prevValues => ({...prevValues, numbers: numbers }))
    }, [numbers])


    function addNumberRow() {

        const tempObject = {phoneNumber: '', phoneLabel: ''}

        setNumbers(prevValues => 
            [...prevValues, tempObject]
        )
    }

    function handleNumberRow(value, index, property) {

        let initialArray = numbers
        let newArray = update(initialArray, {[index]: {[property]: {$set: value}}})

        setNumbers(newArray)

    }

    function removeNumberRow(index) {

        let initialArray = numbers
        let newArray = update(initialArray, {$splice: [[index, 1]]})

        setNumbers(newArray)
    }

    function savePhonebookContact() {

        if(phonebookContacts !== null) {
            var tempId = 0

            phonebookContacts.map((v, i) => 
                v.id > tempId ? tempId = v.id : tempId
            )

            formValues.id = tempId + 1
        }
        else {
            formValues.id = 1
        }

        setPhonebookContacts(prevValues => [
            ...prevValues, formValues
        ])

        setTimeout(() => {
            history.push('/')
        }, 300);

    }

    useEffect(() => {
        localStorage.setItem('phonebookContacts', JSON.stringify(phonebookContacts))
    }, [phonebookContacts])


    return (
        <div className="container add-user">
            <div className="row flex-nowrap">
                <div className="col-auto d-none d-md-flex">
                    <div className="avatar">
                        <i className="cir ci-upload"></i>
                    </div>
                </div>
                <div className="col">
                    <div className="main-line d-none d-md-flex">
                        <Link to="/" className="btn"><i className="cir ci-back"></i></Link>
                    </div>

                    <div className="main-line mobile d-md-none">
                        <Link to="/" className="btn mr-auto ml-n2"><i className="cir ci-back"></i></Link>
                    </div>

                    <div className="mobile-user-display d-md-none">
                        <div className="avatar">
                            <i className="cir ci-upload"></i>
                        </div>
                    </div>

                    <div>
                        <div className="input-fileds">
                            <div className="form-group">
                                <label className="body-2"><i className="cir ci-person"></i><b>full name</b></label>
                                <input type="text" className="form-control width-limited" placeholder="Full name" required
                                    onChange={(e) => setFormValues(prevValues => ({...prevValues, fullName: e.target.value }))}
                                />
                                {validator.message('fullName', formValues.fullName, 'required|min:2|max:30')}
                                <small className="text-danger">{errorMessages['fullName']}</small>
                            </div>
                            <div className="form-group">
                                <label className="body-2"><i className="cir ci-email"></i><b>email</b></label>
                                <input type="email" className="form-control width-limited" placeholder="Email" required
                                    onChange={(e) => setFormValues(prevValues => ({...prevValues, email: e.target.value }))}
                                />
                                {validator.message('email', formValues.email, 'required|email')}
                                <small className="text-danger">{errorMessages['email']}</small>
                            </div>
                            <div className="form-group">
                                <label className="body-2"><i className="cir ci-phone"></i><b>numbers</b></label>

                                {
                                    numbers.map((v, i) => 

                                    <>
                                        <div className="input-collection" key={i}>
                                            <input type="number" value={v.phoneNumber} className="form-control" placeholder="Number" onChange={(e) => handleNumberRow(e.target.value, i, 'phoneNumber')} required />
                                            <input type="text" value={v.phoneLabel} className="form-control" placeholder="Label" onChange={(e) => handleNumberRow(e.target.value, i, 'phoneLabel')} required />
                                            <button className="btn btn-sm btn-outline-gray-300 remove-row" onClick={() => removeNumberRow(i)}><i className="cir ci-times"></i></button>
                                        </div>
                                        
                                        {validator.message('number', v.phoneNumber, 'required|phone')}
                                        <small className="text-danger">{errorMessages['number']}</small>
                                        
                                        {validator.message('label', v.phoneLabel, 'required|alpha')}
                                        <small className="text-danger">{errorMessages['label']}</small>
                                    </>
                                    )
                                }

                                <div>
                                    <div className="add-new-row" onClick={() => addNumberRow()}>
                                        <button className="btn btn-sm btn-outline-primary"><i className="cir ci-times"></i></button>
                                        <span>Add number</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="d-flex justify-content-between new-user-actions">
                            <button onClick={() => history.goBack()} className="btn btn-gray-400">Cancel</button>
                            <button className="btn btn-primary" onClick={() => submitForm()}>Save</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUser;