import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import update from 'react-addons-update';
import avatar from '../images/avatar.jpeg'

function User() {

    const user = useParams()

    const [existingUsers, setExistingUsers] = useState(JSON.parse(localStorage.getItem('phonebookContacts')))
    const [referentUser, setReferentUser] = useState(existingUsers.find(x => x.id == user.userId))


    useEffect(() => {
        localStorage.setItem('phonebookContacts', JSON.stringify(existingUsers))
    }, [existingUsers])

    useEffect(() => {
        setExistingUsers(update(existingUsers, {$splice: [[existingUsers.findIndex(x => x.id == user.userId), 1, referentUser]]}))
    }, [referentUser])

    return (
        <div className="container user-details">
            <div className="row">
                <div className="col-auto avatar d-none d-md-block">
                    <img src={avatar} alt="" />
                </div>
                <div className="col">
                    <div className="main-line d-none d-md-flex">
                        <Link to="/" className="btn"><i className="cir ci-back"></i></Link>
                        <div className="name">{referentUser.fullName}</div>

                        <div className="user-actions">
                            <button className="btn" onClick={() => setReferentUser(prevValues => ({...prevValues, isFavorite: !referentUser.isFavorite}))}><i className={`cir ${referentUser.isFavorite ? `ci-heart-solid` : `ci-heart`}`}></i></button>
                            <Link to={`/edit-user/${referentUser.id}`} className="btn"><i className="cir ci-edit"></i></Link>
                        </div>
                    </div>

                    <div className="main-line mobile d-md-none">
                        <Link to="/" className="btn mr-auto ml-n2"><i className="cir ci-back"></i></Link>

                        <div className="user-actions">
                            <button className="btn" onClick={() => setReferentUser(prevValues => ({...prevValues, isFavorite: !referentUser.isFavorite}))}><i className={`cir ${referentUser.isFavorite ? `ci-heart-solid` : `ci-heart`}`}></i></button>
                            <Link to={`/edit-user/${referentUser.id}`} className="btn"><i className="cir ci-edit"></i></Link>
                        </div>
                    </div>

                    <div className="mobile-user-display d-md-none">
                        <img src={avatar} alt="" className="avatar" />
                        <div className="name">{referentUser.fullName}</div>
                    </div>
                    
                    <div className="user-info">
                        <div>
                            <div className="d-flex align-items-center">
                                <i className="cir ci-email"></i>email
                            </div>
                        </div>
                        <div><a href={`mailto:${referentUser.email}`}>{referentUser.email}</a></div>
                        <div>
                            <div className="d-flex align-items-center">
                                <i className="cir ci-phone"></i>numbers
                            </div>
                        </div>
                        <div className="numbers-grid">
                            {
                                referentUser.numbers.map((v, i) =>
                                <>
                                    <div>{v.phoneLabel}</div>
                                    <div>
                                        <a href={`tel:${v.phoneNumber}`}>{v.phoneNumber}</a>
                                    </div>
                                </>
                                )
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default User;