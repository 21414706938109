import React from 'react'
import './styles/styles.scss'
import { Switch, Route } from 'react-router-dom'
import Header from './components/Header'
import Home from './components/Home'
import User from './components/User'
import AddUser from './components/AddUser'
import EditUser from './components/EditUser'

function App() {
  return (
    <div className="App">
      <div className="main-content pb-5">
        <Header />
        <Switch>
          <Route exact path="/"><Home /></Route>
          <Route path="/users/:userId"><User /></Route>
          <Route path="/add-user"><AddUser /></Route>
          <Route path="/edit-user/:userId"><EditUser /></Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
